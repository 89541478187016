import React from 'react'
import { Link } from 'gatsby'

const BannerLanding = props => (
  <section id="banner" className="style2">
    <div
      className="background-image"
      style={{
        backgroundImage: `url(${props.backgroundImage})`,
      }}
    />
    <div className="inner">
      <header className="major">
        <h1>{props.title}</h1>
      </header>
      <div className="content">
        <p>{props.description}</p>
      </div>
      <ul className="actions">
        <li>
          <Link to="/#work" className="button">
            Back
          </Link>
        </li>
        <li>
          <a
            href={props.to}
            target="_blank"
            rel="noopener noreferrer"
            className="button special"
          >
            Visit Site
          </a>
        </li>
      </ul>
    </div>
  </section>
)

export default BannerLanding
