import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import scssLogo from '../assets/images/scss-logo.png'
import gulpLogo from '../assets/images/gulp-logo.png'
import brazeLogo from '../assets/images/braze-logo.png'
import pizzahutLogo from '../assets/images/pizzahut-green.png'
import pizzahutHutRewards from '../assets/images/pizzahut-hutrewards.png'
import brazeImage from '../assets/images/braze-campaign.png'

const Landing = props => (
  <Layout>
    <Helmet
      title="Tyler Kemme | Pizza Hut"
      meta={[
        {
          name: 'description',
          content: 'Site Redesign + Email Creation',
        },
        {
          name: 'keywords',
          content: 'pizza hut, scss, gulp, salesforce, braze, crm',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>

    <BannerLanding
      title="Pizza Hut"
      description="Site Redesign + Email Creation"
      to="https://www.pizzahut.com/index.php#/hutrewards"
      backgroundImage={pizzahutLogo}
    />

    <div id="main" className="landing-page">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>The Project</h2>
          </header>
          <p>
            When Pizza Hut redesigned their website to use a light theme, they
            had T3 do the designs of their loyalty platform because we developed
            and maintained{' '}
            <a
              href="https://www.pizzahut.com/index.php#/hutrewards"
              target="_blank"
              rel="noopener noreferrer"
            >
              HutRewards
            </a>
            .
            <br />
            <br />
            In addition to redesigning HutRewards, I also did work designing
            Pizza Hut email templates in Salesforce and Braze.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <div className="logo-soup">
            <img
              src={scssLogo}
              alt="SCSS logo"
              className="logo-soup-individual"
            />
            <img
              src={gulpLogo}
              alt="Gulp logo"
              className="logo-soup-individual"
            />
            <img
              src={brazeLogo}
              alt="Braze logo"
              className="logo-soup-individual"
            />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h2>Technologies Used</h2>
              </header>
              <p>
                This site redesign was accomplished using SCSS and Gulp. We used
                Salesforce then eventually Braze to create and send marketing
                emails
                <br />
                <br />
                <ul>
                  <li>
                    <a
                      href="https://sass-lang.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SCSS
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://gulpjs.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gulp
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.braze.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Braze
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </section>
        <section>
          <a
            href="https://www.pizzahut.com/index.php#/hutrewards"
            className="image"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={pizzahutHutRewards} alt="Pizza Hut HutRewards" />
          </a>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>How I contributed</h3>
              </header>
              <p>
                I was responsible for updating the HutRewards section of Pizza
                Hut's website to their new white theme using SCSS and Gulp as
                well as implementing some basic account functionality using PHP.
                Because T3 managed every part of Pizza Hut's loyalty platform,
                we had to be in close contact with Pizza Hut's designers to
                ensure everything matched with the rest of the site.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={brazeImage} alt="T3 Services Page" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Additional Information</h3>
              </header>
              <p>
                In addition to updating Pizza Hut's account/loyalty pages, I
                spent time creating marketing emails for Pizza Hut that were
                sent to millions of customers. Initially these campaigns were
                created in Salesforce. I would create the audience by using SQL
                to select the eligble customers and then design them using CSS.
                Eventually we moved to using Braze instead of Salesforce which
                eliminated the need for complex SQL queries. The Braze emails
                were created using Shopify's templating language,{' '}
                <a
                  href="https://shopify.github.io/liquid/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Liquid
                </a>
                .
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
